@import '../../../../scss/theme-bootstrap';

$gnav-signup-max-width: 380px;

.site-footer-email-signup {
  padding: 45px 0 0;
  width: 100%;
  &:nth-child(2) {
    padding-top: 0;
  }
  @include breakpoint($landscape-up) {
    padding: 47px 0 0;
  }
  &__title {
    @include title--h5;
    font-family: $font--lars-bold;
    color: $color-black;
    margin-bottom: 10px;
    margin-top: 0;
    text-align: $ldirection;
  }
  &__successful-message {
    text-align: $ldirection;
    color: $color-black;
  }
  &__error {
    color: $color-invalid;
  }
  &__fields {
    width: 100%;
    margin: 0 auto;
  }
  &__field {
    display: block;
    width: 100%;
    margin-bottom: 0;
    padding-bottom: 0;
    input {
      padding: 0 10px;
    }
  }
  &--email {
    padding-bottom: 24px;
  }
  input[type='email'],
  input[type='tel'],
  input[type='text'] {
    background: none;
    border: 0;
    border-bottom: 1px solid;
    border-color: $color-black;
    border-radius: 0;
    height: 35px;
    font-size: 14px;
    line-height: 12px;
    padding: 8px 0;
    @include breakpoint($landscape-up) {
      font-size: 12px;
    }
    &:visited {
      border-color: $color-black;
      color: $color-black;
    }
    &:focus,
    &.active {
      border-color: $color-black;
      color: $color-black;
    }
  }
  input[type='checkbox'] ~ .label,
  input[type='checkbox'] ~ label {
    @include swap_direction(padding, 0 0 5px 30px);
  }
  &__gdpr-link {
    cursor: pointer;
    margin-bottom: 20px;
    position: relative;
    text-align: $ldirection;
    font-size: 14px;
    color: $color-black;
    height: 35px;
    line-height: 35px;
    @include breakpoint($landscape-up) {
      font-size: 12px;
      text-align: $ldirection;
    }
    &-copy {
      &:hover ~ .site-footer-email-signup__gdpr-overlay {
        display: block;
      }
    }
  }
  &__gdpr-overlay {
    display: none;
    position: absolute;
    text-decoration: none;
    padding: 15px;
    background-color: $color-black;
    color: $color-white;
    top: 40px;
    width: 100%;
    z-index: 902;
    @include breakpoint($landscape-up) {
      line-height: normal;
      #{$ldirection}: -73%;
      top: 0;
      width: 71%;
    }
    p {
      margin: 0;
      color: $color-white;
      font-size: 14px;
      line-height: 24px;
      @include breakpoint($landscape-up) {
        font-size: 12px;
        line-height: normal;
      }
    }
    .icon--close {
      fill: $color-white;
      width: 20px;
      height: 20px;
      margin-bottom: 5px;
      float: $rdirection;
      @include breakpoint($landscape-up) {
        display: none;
      }
    }
    &-copy {
      float: $ldirection;
      @include breakpoint($landscape-up) {
        float: none;
      }
    }
  }
  &__terms {
    display: none;
    padding: 10px 0 15px;
    position: relative;
    width: 100%;
    color: $color-black;
    &-checkbox {
      span {
        display: inline-block;
        float: $ldirection;
        text-align: justify;
        width: 100%;
      }
      span,
      p {
        font-size: 14px;
        @include breakpoint($landscape-up) {
          font-size: 12px;
        }
      }
    }
    &-more {
      margin: 15px 0 0;
      text-align: $ldirection;
    }
    &-checkbox,
    &-more {
      font-size: 13px;
      margin-bottom: 20px;
    }
  }
  &__submit {
    color: $color-white;
    margin: auto;
    margin-bottom: 0;
    width: 100%;
    @include breakpoint($landscape-up) {
      margin-bottom: 45px;
    }
  }
  &__success {
    color: $color-black;
    font-size: 14px;
    position: relative;
    text-align: $ldirection;
    @include breakpoint($landscape-up) {
      font-size: 12px;
    }
    &-header {
      text-transform: uppercase;
    }
    &-offer {
      line-height: 1.5em;
      &__code {
        text-transform: uppercase;
      }
    }
    &-terms {
      margin-top: 1em;
      margin-bottom: 2em;
    }
    &-cta {
      margin-bottom: 2em;
    }
  }
  &__block--right,
  &__title,
  &__successful-message,
  &__email-content,
  &__sms,
  &__terms-conditions,
  &__cta,
  &__gdpr-link {
    @include breakpoint($landscape-up) {
      width: 57%;
      float: $rdirection;
      &.left {
        float: $ldirection;
      }
    }
    &.center {
      padding: 0 45px;
      @include breakpoint($landscape-up) {
        float: none;
        margin: 0 auto;
        max-width: $gnav-signup-max-width;
        padding: 0;
      }
    }
  }
  &__sms {
    padding-bottom: 24px;
  }
  .error {
    input[type='email'],
    input[type='tel'],
    input[type='text'] {
      border-color: $color-invalid;
      &::placeholder {
        color: $color-invalid;
      }
    }
  }
  &__icon--information {
    @include swap_direction(margin, 8px 10px 0 -2px);
    float: $ldirection;
    .icon--information {
      height: 17px;
      width: 17px;
    }
  }
}
